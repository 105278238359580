import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as fromTranslate from '../index';
import * as TranslateSelectors from '../selectors/translate.selectors';
import * as TranslateActions from '../actions/translate.actions';

@Injectable()
export class TranslateFacade {
  lang$ = this.store.pipe(select(TranslateSelectors.getLang));

  constructor(private store: Store<fromTranslate.State>) {
  }

  selectLang(lang) {
    this.store.dispatch(TranslateActions.selectLanguage({lang}));
  }
}
