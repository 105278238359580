import { Action, createReducer, on } from '@ngrx/store';
import { User } from '../../domain/user';

import * as AuthActions from '../actions/auth.actions';

export const authKey = 'auth';

export interface State {
  user: User;
  config: any;
  isAuthorized: boolean;
  loading: boolean;
  error?: string;
}

export const initialState: State = {
  user: null,
  config: {},
  isAuthorized: false,
  loading: false,
};

const authReducer = createReducer(
  initialState,
  on(AuthActions.loadUserDetails, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(AuthActions.loadUserDetailsSuccess, (state, { user }) => ({
    ...state,
    user,
    loading: false,
  })),
  on(AuthActions.loadUserDetailsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(AuthActions.logout, (state) => ({
    ...initialState,
    config: state.config,
  })),
  on(AuthActions.loadConfigSuccess, (state, { config }) => ({
    ...state,
    config,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}
