import { HttpClient} from '@angular/common/http';
import { PspMultiTranslateHttpLoader } from './PspMultiTranslateHttpLoader';

export const translateResources: ITranslationResource[] = [
  { prefix: './assets/i18n/shared/', suffix: '.json' },
  { prefix: './assets/i18n/home/', suffix: '.json' },
  { prefix: './assets/i18n/search/', suffix: '.json' },
  { prefix: './assets/i18n/public-service/', suffix: '.json' },
  { prefix: './assets/i18n/public-event/', suffix: '.json' },
  { prefix: './assets/i18n/not-found/', suffix: '.json' },
  { prefix: './assets/i18n/terms-and-conditions/', suffix: '.json' },
  { prefix: './assets/i18n/support/', suffix: '.json' },
  { prefix: './assets/i18n/organization/', suffix: '.json' },
  { prefix: './assets/i18n/feedback/', suffix: '.json' },
  { prefix: './assets/i18n/chat/', suffix: '.json' },
];

export function createTranslateLoader(http: HttpClient) {
  return new PspMultiTranslateHttpLoader(http, translateResources);
}

export interface ITranslationResource {
  prefix: string,
  suffix: string
}