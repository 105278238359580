import { Pipe, PipeTransform } from '@angular/core';
import { stripSpecialChars } from '@ps-frontend/psp/shared/util/helpers';
import { get, isString, isUndefined, isFinite, isBoolean } from 'lodash';


@Pipe({
  name: 'psFilterBy',
})
export class FilterByPipe implements PipeTransform {

  /**
   *
   * @param input
   * @param props
   * @param search
   * @param strict
   * @returns {any[]}
   *
   * @Examples
   *
   * Returns service with `title` of 'service title'
   * <p>{{ services | psFilterBy: ['title']: 'service title' }}</p>
   *
   * psFilterBy also support nested properties inside of an array
   * <p>{{ services | psFilterBy: ['group.title']: 'service title' }}</p>
   *
   * Return services whose title or description is 'Title'.
   * <p>{{ services | psFilterBy: ['title', 'description']: 'Title' }}</p>
   *
   */
  transform(input: any, props: string[], search: any = '', strict = false): any[] {
    if (!Array.isArray(input) ||
      !Array.isArray(search) && !isString(search) && !isFinite(search) && !isBoolean(search)) {
      return input;
    }

    const term = stripSpecialChars(String(search)).toLocaleLowerCase();

    return input.filter((obj: any) =>
      props.some((prop) => {
        const value = get(obj, prop);
        const normalized = stripSpecialChars(String(value)).toLocaleLowerCase();

        if (isUndefined(value)) {
          return false;
        }

        return strict ? term===normalized:normalized.includes(term);
      })
    );
  }

}
