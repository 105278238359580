import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthFacade } from '@ps-frontend/psp/auth/domain';
import { DOCUMENT } from '@angular/common';

// tslint:disable-next-line:ban-types
//declare let gtag: Function;

@Component({
  selector: 'ps-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private authFacade: AuthFacade,
    @Inject(DOCUMENT)
    private document: Document,
  ) {}

  ngOnInit() {
    this.setDevWatermark()
  }
  setDevWatermark() {
    this.authFacade.config$.subscribe(config => {
      if (config.host && !['https://servicii.live.egov.md/', 'https://servicii.gov.md/'].includes(config.host) ) {
        this.document.body.classList.add('dev');
      } else {
        this.document.body.classList.remove('dev');
      }
    });
  }
}
