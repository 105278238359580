import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromSearch from './application-services';
import { FiltersEffects } from './application-services/effects/filter.effects';
import { SearchHintsEffects } from './application-services/effects/search-hints.effects';
import { SearchEffects } from './application-services/effects/search.effects';
import { defaultFrom, defaultSize, FROM, SIZE } from './domain/search-result';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromSearch.searchFeatureKey, fromSearch.reducers),
    EffectsModule.forFeature([
      SearchEffects,
      SearchHintsEffects,
      FiltersEffects,
    ]),
  ],
  providers: [
    {
      provide: SIZE,
      useValue: defaultSize,
    },
    {
      provide: FROM,
      useValue: defaultFrom,
    },
  ],
})
export class PspSearchDomainModule {
}
