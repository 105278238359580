import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EllipsisPipe } from './ellipsis/ellipsis.pipe';
import { FilterByPipe } from './filterBy/filter-by.pipe';
import { FullUrlPrefixPipe } from './full-url-prefix/full-url-prefix.pipe';
import { StripUrlPrefixPipe } from './strip-url-prefix/strip-url-prefix.pipe';
import { TranslateResourcePipe } from './translate-resource/translate-resource.pipe';
import { StripTagsPipe } from './strip-tags/strip-tags.pipe';

const pipes = [
  EllipsisPipe,
  FilterByPipe,
  StripUrlPrefixPipe,
  FullUrlPrefixPipe,
  TranslateResourcePipe,
  StripTagsPipe,
];

@NgModule({
  imports: [CommonModule, TranslateModule],
  exports: pipes,
  declarations: pipes,
})
export class PspSharedUtilPipesModule {
}
