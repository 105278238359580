import { some } from 'lodash';

export function stripSpecialChars(text: string) {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function createSelectCustomSearchFn(propName = 'title') {
  return function customSearchFn(term: string, item) {
    term = stripSpecialChars(String(term))
      .toLocaleLowerCase()
      .trim()
      .replace(/ +/g, ' ');
    const splitTerms = term.split(' ');

    return some(item[propName], (title) => {
      const normalized = stripSpecialChars(String(title)).toLocaleLowerCase();

      return splitTerms.every((t) => {
        return normalized.includes(t);
      });
    });
  };
}
