import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { selectUrl, State } from '@ps-frontend/psp/shared/util/state';
import { DEFAULT_LANGUAGE, langList } from '@ps-frontend/psp/translate/utils';
import { RouterUtilsService } from '@ps-frontend/shared/util';
import { includes } from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import * as TranslateActions from '../actions/translate.actions';
import { TranslateFacade } from '../facade/translate.facade';

@Injectable()
export class TranslateEffects {
  navigationStart$ = createEffect(
    () =>
      this.router.events.pipe(
        filter((e) => e instanceof NavigationStart),
        tap((navigationStart: NavigationStart) => {
          const currentLang = this.translate.currentLang;
          let { url } = navigationStart;
          const isRootUrl = url === '/';
          const urlSegments = url.split('/');
          const assertLang = urlSegments[1]?.split('?')[0];
          const urlLang = includes(langList, assertLang) ? assertLang:null;
          const isInitialLoad = this.router.url === '/';

          let lang;

          if (
            !isInitialLoad &&
            currentLang &&
            urlLang &&
            currentLang !== urlLang
          ) {
            this.translateFacade.selectLang(urlLang);
          }

          if (isRootUrl && !isInitialLoad) {
            url += currentLang;
            this.router.navigate([url]);

            return;
          }

          if (isInitialLoad && !currentLang) {
            lang = urlLang ?? this.defaultLanguage;

            this.translateFacade.selectLang(lang);

            if (!urlLang) {
              url = `${lang}${url}`;

              this.router.navigate([url]);
            }
          } else {
            if (!urlLang) {
              lang = currentLang ?? this.defaultLanguage;

              url = `${lang}${url}`;

              this.router.navigate([url]);
            }
          }
        })
      ),
    { dispatch: false }
  );

  langChange$ = createEffect(
    () =>
      this.translate.onLangChange.pipe(
        map((langChange: LangChangeEvent) => langChange.lang),
        withLatestFrom(this.store.pipe(select(selectUrl))),
        tap(([lang, url]) => {
          if (!url) {
            return;
          }

          const urlSegments = url.split('/');
          urlSegments[1] = lang;

          url = urlSegments.join('/');

          this.router.navigateByUrl(url);
        })
      ),
    { dispatch: false }
  );

  selectLan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TranslateActions.selectLanguage),
        tap(({ lang }) => {
          this.translate.use(lang);
          this.cookieService.set('psp_language', lang);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private translate: TranslateService,
    private cookieService: CookieService,
    private router: Router,
    private store: Store<State>,
    private translateFacade: TranslateFacade,
    private routerUtilsService: RouterUtilsService,
    private location: Location,
    @Inject(DEFAULT_LANGUAGE) private defaultLanguage: string
  ) {
  }
}
