import { createAction, props } from '@ngrx/store';
import { SearchQuery, SearchResult, } from '../../domain/search-result';

export const searchPublicInfo = createAction(
  '[Search Results Page] Search Public Info',
  props<{ query?: SearchQuery }>()
);

export const searchSuccess = createAction(
  '[Search/API] Search Success',
  props<{ searchResult: SearchResult }>()
);

export const searchFailure = createAction(
  '[Search/API] Search Failure',
  props<{ error: any }>()
);

export const loadMore = createAction('[Search Results Page] Load more');

export const loadMoreSuccess = createAction(
  '[Search/API] Load More Success',
  props<{ searchResult: SearchResult }>()
);

export const loadMoreFailure = createAction(
  '[Search/API] Load More Failure',
  props<{ error: any }>()
);

export const flushSearch = createAction('[Search Results Page] Flush');

export const noSearch = createAction('[Search Results Page] No Search');

export const applyFilters = createAction(
  '[Search Results Page] Apply Filters',
  props<{ filters: any }>()
);
