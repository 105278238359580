import { createSelector } from '@ngrx/store';
import { selectAuthFeatureState } from '../index';
import { authKey, State } from '../reducers/auth.reducer';

export const getAuthState = createSelector(
  selectAuthFeatureState,
  (state) => state[authKey]
);

export const getUserDetailsLoading = createSelector(
  getAuthState,
  (state: State) => state.loading
);

export const getUserDetailsError = createSelector(
  getAuthState,
  (state: State) => state.error
);

export const getUserDetails = createSelector(
  getAuthState,
  (state: State) => state.user
);

export const getUserName = createSelector(
  getUserDetails,
  (user) => `${user.firstName} ${user.lastName}`
);

export const getIsAuthorized = createSelector(getUserDetails, (user) => !!user);

export const getConfig = createSelector(
  getAuthState,
  (state: State) => state.config
);

export const getRoles = createSelector(getUserDetails, (user) => user.roles);

export const getIsAdmin = createSelector(
  getRoles,
  (roles) => !!roles?.includes('Administrator')
);

export const getRole = createSelector(getRoles, (roles) => {
  if (roles.includes('Administrator')) {
    return 'Administrator';
  }

  if (roles.includes('ServiceProvider')) {
    return 'ServiceProvider';
  }

  if (roles.includes('Manager')) {
    return 'Manager';
  }

  return null;
});

export const getIsInCharge = createSelector(
  getRoles,
  (roles) =>
    !!roles?.some((role) =>
      ['Administrator', 'ServiceProvider', 'Manager'].includes(role)
    )
);
