import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '@ps-frontend/psp/shared/util/state';
import * as fromSearchResult from './reducers/search.reducer';
import * as fromSearchHints from './reducers/search-hints.reducer';
import * as SearchActions from './actions/search.actions';
import * as SearchHintsActions from './actions/search-hints.actions';

export const searchFeatureKey = 'search';

export interface SearchState {
  [fromSearchResult.searchResultFeatureKey]: fromSearchResult.State,
  [fromSearchHints.searchHintsFeatureKey]: fromSearchHints.State,
}

export interface State extends fromRoot.State {
  readonly [searchFeatureKey]: SearchState;
}

export function reducers(state: SearchState | undefined, action: Action) {
  return combineReducers({
    [fromSearchResult.searchResultFeatureKey]: fromSearchResult.reducer,
    [fromSearchHints.searchHintsFeatureKey]: fromSearchHints.reducer,
  })(state, action);
}

export const selectSearchState = createFeatureSelector<State, SearchState>(
  searchFeatureKey
);

export { SearchActions, SearchHintsActions };

