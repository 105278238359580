export interface User {
  username: string;
  firstName: string;
  lastName: string;
  roles: string[];
}

export enum Roles {
  administrator = 'Administrator',
  serviceProvider = 'ServiceProvider',
  manager = 'Manager',
}
