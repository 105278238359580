import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { assign } from 'lodash';
import { FilterConfigDto } from '../../domain/filter';
import { calculateSize, defaultFrom, defaultSize, SearchInfoItem, SearchQuery, } from '../../domain/search-result';
import * as FilterConfigActions from '../actions/filter.actions';
import * as SearchActions from '../actions/search.actions';

export const searchResultFeatureKey = 'searchResults';

export interface State extends EntityState<SearchInfoItem> {
  selectedId?: string;
  loading: boolean;
  query: SearchQuery;
  totalCount: number;
  error?: string;
  filterConfig: FilterConfigDto;
}

export const searchAdapter: EntityAdapter<SearchInfoItem> = createEntityAdapter<SearchInfoItem>();

export const initialState: State = searchAdapter.getInitialState({
  loading: false,
  query: {
    term: '',
    from: defaultFrom,
    size: defaultSize,
  },
  totalCount: 0,
  filterConfig: { payments: {}, beneficiaryTypes: {} } as FilterConfigDto,
});

const searchResultReducer = createReducer(
  initialState,
  on(SearchActions.searchPublicInfo, (state, { query }) => {
    if (!query) {
      return state;
    }

    const nextFrom = query.size ? query.size - defaultSize:defaultFrom;

    return {
      ...state,
      query: {
        ...state.query,
        term: query.term,
        from: nextFrom,
        size: defaultSize,
      },
      loading: true,
      error: null,
    };
  }),
  on(SearchActions.noSearch, (state) => ({ ...state, loading: false })),
  on(SearchActions.searchSuccess, (state, { searchResult }) => {
    const { items, totalCount } = searchResult;

    return searchAdapter.setAll(items, {
      ...state,
      loading: false,
      totalCount,
    });
  }),
  on(SearchActions.loadMore, (state) => {
    const {
      query: { from, size },
    } = state;

    return {
      ...state,
      query: assign({}, state.query, {
        from: calculateSize(size, from),
        size: Number(size),
      }),
      loading: true,
      error: null,
    };
  }),
  on(SearchActions.loadMoreSuccess, (state, { searchResult }) => {
    const { items, totalCount } = searchResult;

    return searchAdapter.addMany(items, {
      ...state,
      loading: false,
      totalCount,
    });
  }),
  on(
    SearchActions.searchFailure,
    SearchActions.loadMoreFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(SearchActions.flushSearch, (state) => ({
    ...state,
    ...{
      query: {
        ...state.query,
        term: '',
        from: defaultFrom,
        size: defaultSize,
      },
      totalCount: 0,
    },
  })),
  on(SearchActions.applyFilters, (state, { filters }) => ({
    ...state,
    query: {
      ...state.query,
      ...filters,
    },
  })),
  on(FilterConfigActions.loadFilterConfig, (state) => ({
    ...state,
    error: null,
  })),
  on(
    FilterConfigActions.loadFilterConfigSuccess,
    (state, { filterConfig }) => ({
      ...state,
      filterConfig,
    })
  ),
  on(FilterConfigActions.loadFilterConfigFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return searchResultReducer(state, action);
}
