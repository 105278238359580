import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { EllipsisPipe } from '@ps-frontend/psp/shared/util/pipes';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    private title: Title,
    private meta: Meta,
    private ellipsisPipe: EllipsisPipe
  ) {}

  updateTitle(title: string = '') {
    title = this.ellipsisPipe.transform(title, 60);

    this.title.setTitle(title);
  }

  updateDescription(description: string) {
    if (description) {
      this.meta.updateTag({
        name: 'description',
        content: this.ellipsisPipe.transform(description, 160),
      });
    } else {
      const descriptionTag = this.meta.getTag('name="description"');

      this.meta.removeTagElement(descriptionTag);
    }
  }

  updateOgTitle(ogTitle: string) {
    if (ogTitle) {
      this.meta.updateTag({
        name: 'og:title',
        content: this.ellipsisPipe.transform(ogTitle, 60),
      });
    } else {
      const ogTitleTag = this.meta.getTag('name="og:title"');

      this.meta.removeTagElement(ogTitleTag);
    }
  }

  updateOgDescription(ogDescription: string) {
    if (ogDescription) {
      this.meta.updateTag({
        name: 'og:description',
        content: this.ellipsisPipe.transform(ogDescription, 160),
      });
    } else {
      const ogDescriptionTag = this.meta.getTag('name="og:description"');

      this.meta.removeTagElement(ogDescriptionTag);
    }
  }

  updateOgUrl(url: string) {
    if (url) {
      this.meta.updateTag({ name: 'og:url', content: url });
    } else {
      const ogUrlTag = this.meta.getTag('name="og:url"');

      this.meta.removeTagElement(ogUrlTag);
    }
  }

  updateOgImage(url: string) {
    if (url) {
      this.meta.updateTag({ name: 'og:image ', content: url });
    } else {
      const ogImageTag = this.meta.getTag('name="og:image"');

      this.meta.removeTagElement(ogImageTag);
    }
  }
}
