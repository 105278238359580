import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterUtilsService {

  getRoute(router: Router | ActivatedRouteSnapshot | RouterStateSnapshot | ActivatedRoute): ActivatedRoute | ActivatedRouteSnapshot {
    let route = (<Router>router).routerState?.root || (<RouterStateSnapshot>router).root;
    while (route.firstChild) {
      route = route.firstChild;
    }

    return route;
  }
}
