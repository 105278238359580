import { Action, createReducer, on } from '@ngrx/store';
import { FeedbackDto, } from '../../domain/feedback';
import * as FeedbackActions from '../actions/feedback.actions';

export const feedbackEntityFeatureKey = 'feedbackEntity';

export interface State {
  feedback: FeedbackDto;
  loaded: boolean;
  error?: string;
  canSendFeedback?: boolean;
  showFeedbackDialog: boolean;
}

export const initialState: State = {
  feedback: <FeedbackDto>{},
  loaded: false,
  canSendFeedback: true,
  showFeedbackDialog: false,
};

const feedbackReducer = createReducer(
  initialState,
  on(FeedbackActions.createFeedback, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(FeedbackActions.createFeedbackSuccess, (state, { newFeedback }) => ({
    ...state,
    loaded: true,
    feedback: newFeedback,
    canSendFeedback: false
  })),
  on(FeedbackActions.createFeedbackFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    error,
  })),
  on(
    FeedbackActions.updateFeedbackSuccess,
    FeedbackActions.updateFeedbackFailure,
    (state) => ({
      ...state,
      loaded: true,
      canSendFeedback: false,
      showFeedbackDialog: false
    })
  ),
  on(
    FeedbackActions.updateFeedbackSuccess,
    (state, { updatedFeedback }) => ({
      ...state,
      feedback: updatedFeedback
    })
  ),
  on(FeedbackActions.submitFeedback, (state) => ({
    ...state,
    loaded: false,
  })),
  on(FeedbackActions.rateService, (state) => ({
    ...state,
    loaded: false,
  })),
  on(FeedbackActions.showFeedbackDialog, (state, { status }) => ({
    ...state,
    showFeedbackDialog: status
  })),
  on(FeedbackActions.toggleCanSendFeedback, (state, { canSend }) => ({
    ...state,
    canSendFeedback: canSend
  })),
  on(FeedbackActions.clearState, (state) => ({
    ...state,
    loaded: false,
    feedback: <FeedbackDto>{},
    canSendFeedback: true,
    showFeedbackDialog: false,
    error: null
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return feedbackReducer(state, action);
}
