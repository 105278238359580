import { createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '@ps-frontend/psp/shared/util/state';
import * as fromLang from '../application-services/reducers/translate.reducer';

export const translateFeatureKey = 'translate';

export interface State extends fromRoot.State {
  readonly [translateFeatureKey]: fromLang.State
}

export function reducers(state: fromLang.State, action) {
  return fromLang.reducer(state, action);
}

export const selectTranslateFeatureState = createFeatureSelector<State, fromLang.State>(translateFeatureKey);
