<ng-container *ngIf="authFacade.isAuthorized$ | async; else notAuthorized">
  <div class="btn-group" dropdown>
    <button
      id="user-menu-toggle"
      dropdownToggle
      type="button"
      class="auth-bar btn d-flex align-items-center"
      aria-controls="user-menu"
    >
      <span class="ps-icon-avatar item-icon"></span>
      <span class="item-text">{{ authFacade.userName$ | async }}</span>
    </button>
    <ul
      id="user-menu"
      *dropdownMenu
      class="dropdown-menu"
      role="menu"
      aria-labelledby="user-menu-toggle"
    >
      <li role="menuitem">
        <a
          class="dropdown-item"
          [href]="(authFacade.config$ | async).mpassUrl"
          target="_blank"
        >
          {{ 'header.auth.profile' | translate }}
        </a>
      </li>
      <li role="menuitem">
        <a
          class="dropdown-item"
          [href]="(authFacade.config$ | async).mCabinetUrl"
          target="_blank"
          >{{ 'header.auth.citizenPortal' | translate }}
        </a>
      </li>
    </ul>
    <a
      href="rsspa-api/account/logout"
      class="auth-bar d-flex align-items-center ml-4"
    >
      <span class="ps-icon-login item-icon"></span>
      <span class="item-text">{{ 'header.auth.logout' | translate }}</span>
    </a>
  </div>
</ng-container>

<ng-template #notAuthorized>
  <a href="rsspa-api/account/login" class="auth-bar d-flex align-items-center">
    <span class="ps-icon-login item-icon"></span>
    <span class="item-text">{{ 'header.login' | translate }}</span>
  </a>
</ng-template>
