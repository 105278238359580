import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LetDirective, PushPipe } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { PspAuthFeatureModule } from '@ps-frontend/psp/auth/feature';
import { PspTranslateDomainModule } from '@ps-frontend/psp/translate/domain';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopupComponent } from './popup/popup.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonsModule,
    TranslateModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    PspAuthFeatureModule,
    PspTranslateDomainModule,
    LetDirective, PushPipe,
  ],
  declarations: [HeaderComponent, FooterComponent, PopupComponent],
  exports: [HeaderComponent, FooterComponent, PopupComponent],
})
export class PspSharedLayoutModule {}
