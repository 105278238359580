import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'psStripTags'
})
export class StripTagsPipe implements PipeTransform {

  transform(text: string): string {
    return text?.replace(/<(?:.|\s)*?>/g, '')
      .replace(/%/g, '')
      .replace(/&#x(\d+[\w]);/gi, '%$1');
  }

}
