import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http'
import { TranslateLoader } from '@ngx-translate/core'
import * as merge from 'deepmerge'
import { forkJoin, Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { ITranslationResource } from './translate-loader'

export class PspMultiTranslateHttpLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private resources: ITranslationResource[],
  ) {}

  public getTranslation(lang: string): Observable<any> {
    const headers = new HttpHeaders().set('X-Skip-Interceptor', '')
    const requests = this.resources.map((resource) => {
    const path = resource.prefix + lang + resource.suffix

      return this.http.get(path, { headers }).pipe(
        catchError(() => of({})),
      )
    })

    return forkJoin(requests).pipe(map((response) => merge.all(response)))
  }
}