import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'psFullUrlPrefix',
})
export class FullUrlPrefixPipe implements PipeTransform {
  transform(url: string): string {
    if (!url || url.includes('http'))
      return url;
    else
      return `http://${url}`;
  }
}
