import { DOCUMENT, Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  Optional,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, GuardsCheckEnd, Router } from '@angular/router';
import { AuthFacade } from '@ps-frontend/psp/auth/domain';
import { TranslateFacade } from '@ps-frontend/psp/translate/domain';
import { LANG_LIST } from '@ps-frontend/psp/translate/utils';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ps-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnDestroy {
  destroy$ = new Subject<boolean>();
  guardCheckEnd$ = this.router.events.pipe(
    filter((e) => e instanceof GuardsCheckEnd)
  );
  isMenuOpened = new BehaviorSubject<boolean>(false);
  urlAfterRedirects = new BehaviorSubject<string>('');

  constructor(
    public translateFacade: TranslateFacade,
    private router: Router,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    public authFacade: AuthFacade,
    @Optional() @Inject(DOCUMENT) private document: Document,
    @Inject(LANG_LIST) public langList: string
  ) {
    this.guardCheckEnd$
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: GuardsCheckEnd) => {
        this.urlAfterRedirects.next(event.urlAfterRedirects);

        if (this.isMenuOpened.getValue()) {
          this.hideMenu();
        }
      });
  }

  toggleMenu($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    if (!this.isMenuOpened.getValue()) {
      this.router
        .navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: {
            view: 'menu',
          },
          queryParamsHandling: 'merge',
        })
        .then(() => {
          this.showMenu();
        });
    } else {
      this.location.back();
    }
  }

  showMenu() {
    this.isMenuOpened.next(true);
    this.renderer.addClass(
      this.document?.querySelector('body'),
      'overflow-hidden'
    );
  }

  hideMenu() {
    this.isMenuOpened.next(false);
    this.renderer.removeClass(
      this.document?.querySelector('body'),
      'overflow-hidden'
    );
  }

  changeLang(lang: any) {
    this.translateFacade.selectLang(lang);
  }

  ngOnDestroy(): void {
     this.destroy$.next(true);
    this.destroy$.complete();
  }
}
