<footer class="footer container-xl">
  <div class="main-footer">
    <div class="main-footer-container">
      <div class="logo-container">
        <img
          alt="Footer Logo"
          class="logo-image"
          src="assets/images/logo-monochrome.svg"
        />
      </div>
      <div class="nav-container">
        <ul>
          <li>
            <a [routerLink]="['/terms-and-conditions']">{{
              'terms' | translate
            }}</a>
          </li>
          <li>
            <a [routerLink]="['catalog']">{{
              'header.menu.services' | translate
            }}</a>
          </li>
          <li>
            <a [routerLink]="['organizations']">{{
              'header.menu.organizations' | translate
            }}</a>
          </li>
          <li>
            <a href="https://mpay.gov.md/Services" target="_blank">{{
              'header.menu.payment' | translate
            }}</a>
          </li>
          <li>
            <a [routerLink]="['support']">{{
              'header.menu.help' | translate
            }}</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="contacts-container">
      <div class="phone-container">
        <div class="phone-text">
          <span>{{ 'clientSupport' | translate }}</span>
        </div>
        <!--<div class="phone-number mt-1">
          <a href="tel: 022820023">
            <span class="phone-code">022</span
            ><span class="phone">&#32;820&#32;023</span>
            <img
              alt="Phone Icon"
              class="phone-image"
              src="assets/images/phone.svg"
          /></a>
        </div>-->
        <div class="email mt-1">
          <a href="mailto: support.psp@egov.md">
          <!--  <span class="phone-code">022</span
            >--><span class="email">support.psp@egov.md</span>
            <img
              alt="Envelope Icon"
              class="email-image"
              src="assets/images/envelope.svg"
            /></a>
        </div>
      </div>

      <div class="logo-socials">
        <div class="gov-logo-container">
          <img
            alt="Gov Logo"
            class="gov-logo"
            src="assets/images/gov-logo.svg"
          />
        </div>
        <div class="socials">
          <a
            href="https://www.facebook.com/eGovernanceAgency.Moldova"
            target="_blank"
            class="facebook"
          >
            <img alt="facebook" src="assets/images/facebook.svg" />
          </a>
          <a
            href="https://www.linkedin.com/company/e-government-center"
            target="_blank"
            class="linked-in"
          >
            <img alt="linked in" src="assets/images/linked-in.svg" />
          </a>
          <a
            href="https://twitter.com/eGovCenterMD"
            target="_blank"
            class="twitter"
          >
            <img alt="twitter" src="assets/images/twitter.svg" />
          </a>
          <a
            href="https://www.instagram.com/e_guvernare_moldova"
            target="_blank"
            class="instagram"
          >
            <img alt="instagram" src="assets/images/instagram.svg" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCQiYfbOzuf2A4GH2R_inZfQ"
            target="_blank"
            class="u-tube"
          >
            <img alt="you tube" src="assets/images/u-tube.svg" />
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-downside">
    <div class="rights">
      <span class="copyright-icon">&#169;</span> {{ currentYear }} {{ 'rights' | translate }}
    </div>
  </div>
</footer>
