import { Injectable } from '@angular/core';

import { fetch } from '@nrwl/angular';
import { Observable, of, switchMap } from 'rxjs';
import { Action } from '@ngrx/store';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AuthService } from '@ps-frontend/psp/auth/domain';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AuthActions from '../actions/auth.actions';

@Injectable()
export class AuthEffects {
  loadAuth$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(AuthActions.loadUserDetails),
      switchMap(() =>
        this.authService.getUserDetails().pipe(
          mergeMap(response => {
            if (response.status === 204) {
              return;
            } else {
              return of(AuthActions.loadUserDetailsSuccess({ user: response.body }));
            }
          }),
          catchError(error => of(AuthActions.loadUserDetailsFailure({ error })))
        )
      )
    )
  );
  constructor(private actions$: Actions, private authService: AuthService) {}
}
