import { DeliveryUnitInfo } from '@ps-frontend/psp/shared/util/models';
import { assign, chain, map } from 'lodash';

export function mapDeliveryUnits(deliveryUnits: DeliveryUnitInfo[]) {
  return map(deliveryUnits, (deliveryUnit: DeliveryUnitInfo) =>
    assign(deliveryUnit, {
      availabilities: map(deliveryUnit.availabilities, (availability) =>
        assign(availability, {
          days: groupWeekDays(availability.days),
        })
      ),
    })
  );
}

export function groupWeekDays(weekDays: number[]): (number | number[])[] {
  return chain(weekDays)
    .sortBy()
    .reduce((result, value, index, collection) => {
      const currentIndex = result.length ? result.length - 1 : result.length;
      let nextIndex;

      if (value - 1 === collection[index - 1]) {
        (result[currentIndex] = result[currentIndex] ?? []).push(value);
      } else if (value + 1 === collection[index + 1]) {
        nextIndex = result[currentIndex] ? currentIndex + 1 : currentIndex;
        (result[nextIndex] = result[nextIndex] ?? []).push(value);
      } else {
        result.push(value);
      }

      return result;
    }, [])
    .value();
}
