import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { EllipsisPipe } from '@ps-frontend/psp/shared/util/pipes';
import { SeoEffects } from './application-services/effects/seo.effects';

@NgModule({
  imports: [CommonModule, EffectsModule.forFeature([SeoEffects])],
  providers: [EllipsisPipe],
})
export class SharedSeoDomainModule {}
