import { createSelector } from '@ngrx/store';
import { selectFeedbackState } from '../index';
import { feedbackEntityFeatureKey, State, } from '../reducers/feedback.reducer';

export const getFeedbackEntityState = createSelector(
  selectFeedbackState,
  (state) => state[feedbackEntityFeatureKey]
);

export const getFeedback = createSelector(
  getFeedbackEntityState,
  (state) => state.feedback
);

export const getFeedbackId = createSelector(
  getFeedback,
  (feedback) => feedback.id
);

export const isPositiveFeedback = createSelector(
  getFeedback,
  (feedback) => !feedback ? null:feedback.isPositive
);

export const showFeedbackDialog = createSelector(
  getFeedbackEntityState,
  (state: State) => state.showFeedbackDialog
);

export const canSendFeedback = createSelector(
  getFeedbackEntityState,
  (state: State) => state.canSendFeedback
);

export const showComment = createSelector(
  isPositiveFeedback,
  getFeedback,
  (isPositive: boolean, feedback) => isPositive && !feedback.commentary
);
