import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { FeedbackDto, CreateFeedbackDto } from '../../domain/feedback';
import { FeedbackService } from '../../infrastructure/feedback.service';
import * as FeedbackActions from '../actions/feedback.actions';
import { FeedbackFacade } from '../facades/feedback.facade';

@Injectable()
export class FeedbackEffects {
  rateService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedbackActions.rateService),
      switchMap(({ feedback }) =>
        this.recaptchaV3Service.execute('feedbackCreate').pipe(
          map((token) =>
            FeedbackActions.createFeedback({
              feedback: {
                ...feedback,
                reCaptchaToken: token,
              } as CreateFeedbackDto,
            })
          ),
          catchError(({ error }) =>
            of(FeedbackActions.createFeedbackFailure({ error }))
          )
        )
      )
    )
  );

  createFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedbackActions.createFeedback),
      switchMap(({ feedback }) =>
        this.feedbackService.createFeedback(feedback).pipe(
          map((newFeedback) =>
            FeedbackActions.createFeedbackSuccess({ newFeedback })
          ),
          catchError(({ error }) =>
            of(FeedbackActions.createFeedbackFailure({ error }))
          )
        )
      )
    )
  );

  createNegativeFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedbackActions.createNegativeFeedback),
      switchMap(({ feedback }) =>
        this.feedbackService.createFeedback(feedback).pipe(
          map((updatedFeedback) =>
            FeedbackActions.updateFeedbackSuccess({ updatedFeedback })
          ),
          catchError(({ error }) =>
            of(FeedbackActions.updateFeedbackFailure({ error }))
          )
        )
      )
    )
  );

  submitFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedbackActions.submitFeedback),
      switchMap(({ id, feedback }) =>
        this.recaptchaV3Service.execute('feedbackUpdate').pipe(
          map((token) =>
            FeedbackActions.updateFeedback({
              id,
              feedback: {
                ...feedback,
                reCaptchaToken: token,
              } as FeedbackDto,
            })
          ),
          catchError(({ error }) =>
            of(FeedbackActions.updateFeedbackFailure({ error }))
          )
        )
      )
    )
  );

  submitNegativeFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedbackActions.submitNegativeFeedback),
      switchMap(({ feedback }) =>
        this.recaptchaV3Service.execute('feedbackNegativeUpdate').pipe(
          map((token) =>
            FeedbackActions.createNegativeFeedback({
              feedback: {
                ...feedback,
                reCaptchaToken: token,
              }
            })
          ),
          catchError(({ error }) =>
            of(FeedbackActions.updateFeedbackFailure({ error }))
          )
        )
      )
    )
  );

  updateFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedbackActions.updateFeedback),
      switchMap(({ id, feedback }) =>
        this.feedbackService.updateFeedback(id, feedback).pipe(
          map((updatedFeedback) =>
            FeedbackActions.updateFeedbackSuccess({ updatedFeedback })
          ),
          catchError(({ error }) =>
            of(FeedbackActions.updateFeedbackFailure({ error }))
          )
        )
      )
    )
  );

  sendFeedback = createEffect(() => this.actions$.pipe(
    ofType(FeedbackActions.sendFeedback),
    withLatestFrom(this.feedbackFacade.feedbackId$),
    map(([{ feedback }, feedbackId]) => {
      if (feedbackId) {
        return FeedbackActions.submitFeedback({ id: feedbackId, feedback });
      } else {
        return FeedbackActions.submitNegativeFeedback({
          feedback
        });
      }
    })
    )
  );

  constructor(
    private actions$: Actions,
    private feedbackService: FeedbackService,
    private feedbackFacade: FeedbackFacade,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
  }
}
