import { createReducer, on, Action } from '@ngrx/store';
import { AvailableLangs } from '@ps-frontend/psp/translate/utils';
import * as TranslateActions from '../actions/translate.actions';

export const TRANSLATE_FEATURE_KEY = 'translate';

export interface State {
  lang: string;
}

export const initialState: State = {
  lang: AvailableLangs.Ro
};

const translateReducer = createReducer(
  initialState,
  on(TranslateActions.selectLanguage, (state, {lang}) => ({...state, lang}))
);

export function reducer(state: State | undefined, action: Action) {
  return translateReducer(state, action);
}
