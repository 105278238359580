import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Resource } from '@ps-frontend/psp/shared/util/models';
import { isObject } from 'lodash';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'translateResource',
  pure: false,
})
export class TranslateResourcePipe implements PipeTransform, OnDestroy {
  onLangChange: Subscription;
  resource: Resource;
  value;

  constructor(
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  transform(value: Resource | string, ...args: any[]) {
    const lang = this.translate.currentLang;
    const defaultLang = this.translate.defaultLang;
    const isResource = isObject(value) || !value;

    if (!this.onLangChange) {
      this.onLangChange = this.translate.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          const { lang: l } = event;

          this.value = isResource
            ? this.resource[l] || this.resource[defaultLang]
            : this.translate.instant(value as string);
          this.cdr?.markForCheck();
        }
      );
    }

    if (isResource) {
      this.resource = (value ?? {
        ro: '',
        ru: '',
        en: '',
      }) as Resource;
      this.value = this.resource[lang] || this.resource[defaultLang];
    } else {
      this.value = this.translate.instant(value as string);
    }

    return this.value;
  }

  ngOnDestroy(): void {
    if (typeof this.onLangChange !== 'undefined') {
      this.onLangChange.unsubscribe();
      this.onLangChange = undefined;
    }
  }
}
