import { createAction, props } from '@ngrx/store';
import { FeedbackDto, CreateFeedbackDto } from '../../domain/feedback';

export const rateService = createAction(
  '[Public Service Page] Rate Feedback',
  props<{ feedback: CreateFeedbackDto }>()
);

export const createFeedback = createAction(
  '[Public Service Page] Create Feedback',
  props<{ feedback: CreateFeedbackDto }>()
);

export const createNegativeFeedback = createAction(
  '[Public Service Page] Create Negative Feedback',
  props<{ feedback: CreateFeedbackDto }>()
);

export const createFeedbackSuccess = createAction(
  '[Public Service/API] Create Feedback Success',
  props<{ newFeedback: FeedbackDto }>()
);

export const createFeedbackFailure = createAction(
  '[Public Service/API] Create Feedback Failure',
  props<{ error: any }>()
);

export const updateFeedback = createAction(
  '[Public Service Page] Update Feedback',
  props<{ id: string; feedback: FeedbackDto }>()
);

export const updateFeedbackSuccess = createAction(
  '[Public Service/API] Update Feedback Success',
  props<{ updatedFeedback: FeedbackDto }>()
);

export const updateFeedbackFailure = createAction(
  '[Public Service/API] Update Feedback Failure',
  props<{ error: any }>()
);

export const submitFeedback = createAction(
  '[Public Service Page] Submit Feedback',
  props<{ id: string; feedback: FeedbackDto }>()
);

export const submitNegativeFeedback = createAction(
  '[Public Service Page] Submit Negative Feedback',
  props<{ feedback: CreateFeedbackDto }>()
);

export const showFeedbackDialog = createAction(
  '[Public Service Page] Show Feedback Dialog',
  props<{ status: boolean }>()
);

export const toggleCanSendFeedback = createAction(
  '[Public Service Page] Can Send Feedback',
  props<{ canSend: boolean }>()
);

export const sendFeedback = createAction(
  '[Public Service Page] Send Feedback',
  props<{ feedback: any }>()
);

export const clearState = createAction(
  '[Public Service Page] Clear Feedback State'
);
