import { isPlatformBrowser } from '@angular/common';
import { FactoryProvider, InjectionToken, PLATFORM_ID } from '@angular/core';

export const LOCAL_STORAGE = new InjectionToken('LocalStorageToken');

export function localStorageFactory(platformId: Object): Storage | Object {
  if (isPlatformBrowser(platformId)) {
    return localStorage;
  }

  return {
    setItem() {
    },
    getItem() {
      return '{}';
    },
    removeItem() {
    },
    key() {
    },
    clear() {
    },
  };
}

export const localStorageProvider: FactoryProvider = {
  provide: LOCAL_STORAGE,
  useFactory: localStorageFactory,
  deps: [PLATFORM_ID],
};
