import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromAuth from './application-services/index';
import { AuthEffects } from './application-services/effects/auth.effects';
import { AuthFacade } from './application-services/facade/auth.facade';
import { AuthInterceptor } from './infrastructure/auth.interceptor';
import { AuthService } from './infrastructure/auth.service';

export function checkAuthOnInit(authService: AuthService) {
  return () => authService.checkAuth();
}

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.reducers),
    EffectsModule.forFeature([AuthEffects])
  ],
  providers: [
    AuthFacade,
    {
      provide: APP_INITIALIZER,
      useFactory: checkAuthOnInit,
      deps: [AuthService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ]
})
export class PspAuthDomainModule {
}
