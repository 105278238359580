import { DOCUMENT } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ps-frontend/psp/shared/util/tokens';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document
  ) {}

  saveFile = (response: HttpResponse<any>) => {
    console.log('ssaveFile', response);

    const contentDisposition = response.headers
      .get('content-disposition')
      ?.split(';');
    const fileName = contentDisposition
      .find((item) => item.includes('filename='))
      ?.split('=')[1]
      ?.replace(/['"]/g, '');
    const isBlob = response.body?.constructor?.name === 'Blob';

    let url;

    if (!isBlob) {
      const contentType = response.headers.get('content-type');
      const BlobCtor = (this.window as any).Blob;
      const blob = BlobCtor
        ? new BlobCtor([response.body], {
            type: contentType,
          })
        : null;

      url = blob ?? (this.window as any).URL?.createObjectURL(blob);
    } else {
      url = (this.window as any).URL?.createObjectURL(response.body);
    }

    const a = this.document.createElement('a');

    a.href = url;
    a.download = fileName;
    a.click();
  };
}
