import { createAction, props } from '@ngrx/store';
import { User } from '../../domain/user';

export const loadUserDetails = createAction('[Auth] Load User Details');

export const loadUserDetailsSuccess = createAction(
  '[Auth] Load User Details Success',
  props<{ user: User }>()
);

export const loadUserDetailsFailure = createAction(
  '[Auth] Load User Details Failure',
  props<{ error: any }>()
);

export const loadConfigSuccess = createAction(
  '[Auth] Load Config Success',
  props<{ config: any }>()
);

export const logout = createAction('[Auth] Logout');
