import { Action, createReducer, on } from '@ngrx/store';
import { GroupedHint } from '../../domain/hint';
import * as SearchHintActions from '../actions/search-hints.actions';

export const searchHintsFeatureKey = 'searchHints';

export interface State {
  hints: GroupedHint[];
  loaded: boolean;
  error?: string
}

export const initialState: State = {
  hints: [],
  loaded: false,
};

const searchHintsReducer = createReducer(
  initialState,
  on(SearchHintActions.searchPublicInfoHints, state => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(SearchHintActions.searchPublicInfoHintsSuccess, (state, {hints}) => ({
    ...state,
    hints,
    loaded: true
  })),
  on(SearchHintActions.searchPublicInfoHintsFailure, (state, {error}) => ({
    ...state,
    error
  })),
  on(SearchHintActions.flushSearchHints, () => ({...initialState}))
);

export function reducer(state: State | undefined, action: Action) {
  return searchHintsReducer(state, action);
}
