import { createAction, props } from '@ngrx/store';
import { FilterConfigDto } from '../../domain/filter';

export const loadFilterConfig = createAction('[Saerch] Load Filter Config');

export const loadFilterConfigSuccess = createAction(
  '[Config/Filters/API] Load Filters Success',
  props<{ filterConfig: FilterConfigDto }>()
);

export const loadFilterConfigFailure = createAction(
  '[Config/Filters/API] Load Filters Failure',
  props<{ error: any }>()
);
