import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthFacade } from '@ps-frontend/psp/auth/domain';

@Component({
  selector: 'ps-auth-bar',
  templateUrl: './auth-bar.component.html',
  styleUrls: ['./auth-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthBarComponent {

  constructor(public authFacade: AuthFacade) {
  }

}
