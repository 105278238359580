import { createAction, props } from '@ngrx/store';
import { GroupedHint, SearchQuery } from '@ps-frontend/psp/search/domain';

export const goToSearch = createAction(
  '[Search Hint Component] Go to search',
  props<{ query: SearchQuery }>()
);

export const searchPublicInfoHints = createAction(
  '[Search Hint Component] Search Public Info Hints',
  props<{ query: string }>()
);

export const searchPublicInfoHintsSuccess = createAction(
  '[Search Hint/Api] Search Public Info Hints Success',
  props<{ hints: GroupedHint[] }>()
);

export const searchPublicInfoHintsFailure = createAction(
  '[Search Hint/Api] Search Public Info Hints Failure',
  props<{ error: any }>()
);

export const flushSearchHints = createAction(
  '[Search Hint Component] Flush'
);
