import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PspAuthDomainModule } from '@ps-frontend/psp/auth/domain';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AuthBarComponent } from './auth-bar/auth-bar.component';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    PspAuthDomainModule,
    BsDropdownModule.forRoot()
  ],
  declarations: [AuthBarComponent],
  exports: [AuthBarComponent]
})
export class PspAuthFeatureModule {
}
