import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromTranslate from './application-services/index';
import { TranslateEffects } from './application-services/effects/translate.effects';
import { TranslateFacade } from './application-services/facade/translate.facade';

@NgModule({
  imports: [
    StoreModule.forFeature(
      fromTranslate.translateFeatureKey,
      fromTranslate.reducers
    ),
    EffectsModule.forFeature([TranslateEffects])
  ],
  providers: [TranslateFacade]
})
export class PspTranslateDomainModule {
}
