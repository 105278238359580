import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { User } from '../../domain/user';
import * as AuthActions from '../actions/auth.actions';

import * as fromAuth from '../index';
import * as AuthSelectors from '../selectors/auth.selectors';

@Injectable()
export class AuthFacade {
  userDetailsLoading$ = this.store.pipe(
    select(AuthSelectors.getUserDetailsLoading)
  );
  user$ = this.store.pipe(select(AuthSelectors.getUserDetails));
  userName$ = this.store.pipe(select(AuthSelectors.getUserName));
  role$ = this.store.pipe(select(AuthSelectors.getRole));
  isInCharge$ = this.store.pipe(select(AuthSelectors.getIsInCharge));
  isAuthorized$ = this.store.pipe(select(AuthSelectors.getIsAuthorized));
  config$ = this.store.pipe(select(AuthSelectors.getConfig));

  constructor(private store: Store<fromAuth.State>) {}

  getUserDetails() {
    this.store.dispatch(AuthActions.loadUserDetails());
  }

  getUserDetailsSuccess(user: User) {
    this.store.dispatch(AuthActions.loadUserDetailsSuccess({ user }));
  }

  getConfigSuccess(config) {
    this.store.dispatch(AuthActions.loadConfigSuccess({ config }));
  }

  logout() {
    this.store.dispatch(AuthActions.logout());
  }
}
