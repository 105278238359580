import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createParamsList, normalizeParams, } from '@ps-frontend/psp/shared/util/helpers';
import { chain, reduce } from 'lodash';
import { map } from 'rxjs/operators';
import { FilterConfigDto } from '../domain/filter';
import { Hint } from '../domain/hint';
import { SearchResult } from '../domain/search-result';

@Injectable({
  providedIn: 'root',
})
export class PublicInfoSearchService {
  constructor(private http: HttpClient) {
  }

  search(query) {
    return this.http.get<SearchResult>(`rsspa-api/search/public-info`, {
      params: normalizeParams(query),
    });
  }

  getHints(term) {
    return this.http
      .get<Hint[]>(`rsspa-api/search/public-info/hints`, {
        params: {
          term,
        },
      })
      .pipe(
        map((hints) => {
          return chain(hints)
            .groupBy('type')
            .map((value, key) => ({ type: key, hints: value }))
            .orderBy((el) => el.type === 'PublicEvent')
            .value();
        })
      );
  }

  getFilterConfiguration() {
    return this.http.get<FilterConfigDto>('rsspa-api/config/filters');
  }

  fromQuery(values) {
    return reduce(
      values,
      (result, value) => {
        return {
          ...result,
          [value]: true,
        };
      },
      {}
    );
  }

  createFilterFormFromQuery(query) {
    return {
      types: this.fromQuery(query.types),
      payment: this.fromQuery(query.payment),
      beneficiaryTypes: this.fromQuery(query.beneficiaryTypes),
      eService: !!query.serviceType,
    };
  }

  createFilter(filtersRaw) {
    return {
      serviceType: filtersRaw.eService ? 'eService':'',
      types: createParamsList(filtersRaw.types),
      payment: createParamsList(filtersRaw.payment),
      beneficiaryTypes: createParamsList(filtersRaw.beneficiaryTypes),
    };
  }
}
