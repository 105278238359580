<div class="container-xl not-found-container">
  <div class="not-found-wrapper">
    <div class="not-found">
      <h1 class="code">404</h1>
      <h2 class="title">{{'notFound.title' | translate}}</h2>
    </div>
    <span class="description">{{'notFound.description' | translate}}</span>
    <div>
      <a [routerLink]="['/']" class="btn btb-psp btn-not-found">{{'buttons.home' | translate}}</a>
    </div>
  </div>
</div>

