import { isNil, isString, omitBy, reduce } from 'lodash';

export function normalizeParams(props) {
  return omitBy(props, (val) => isNil(val) || (isString(val) && !val));
}

export function createParamsList(params: { [p: string]: boolean }) {
  return reduce(
    params,
    (result, value, key) => {
      if (value) {
        result.push(key);
      }

      return result;
    },
    []
  );
}
