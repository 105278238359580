export enum AvailableLangs {
  Ru = 'ru',
  En = 'en',
  Ro = 'ro'
}

export const langList = [AvailableLangs.Ro, AvailableLangs.Ru, AvailableLangs.En];
export const langListTemp = [AvailableLangs.Ro];


export const langMap = {
  [AvailableLangs.Ro]: AvailableLangs.Ro,
  [AvailableLangs.Ru]: AvailableLangs.Ru,
  [AvailableLangs.En]: AvailableLangs.En,
};
