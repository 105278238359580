import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RouterUtilsService } from '@ps-frontend/shared/util';
import { asyncScheduler, of } from 'rxjs';
import { catchError, debounceTime, map, skip, switchMap, takeUntil, tap, withLatestFrom, } from 'rxjs/operators';
import { GroupedHint } from '../../domain/hint';
import { SIZE } from '../../domain/search-result';
import { PublicInfoSearchService } from '../../infastructure/public-info-search.service';
import * as SearchHintsActions from '../actions/search-hints.actions';
import { SearchFacade } from '../facades/search.facade';

@Injectable()
export class SearchHintsEffects {
  searchHint$ = createEffect(
    ({ debounce = 300, scheduler = asyncScheduler } = {}) =>
      this.actions$.pipe(
        ofType(SearchHintsActions.searchPublicInfoHints),
        debounceTime(debounce, scheduler),
        switchMap(({ query }) => {
          if (query === '' || query.length < 2) {
            return of(
              SearchHintsActions.searchPublicInfoHintsSuccess({ hints: [] })
            );
          }

          const nextSearch$ = this.actions$.pipe(
            ofType(SearchHintsActions.searchPublicInfoHints),
            skip(1)
          );

          return this.publicInfoService.getHints(query).pipe(
            takeUntil(nextSearch$),
            map((hints: GroupedHint[]) =>
              SearchHintsActions.searchPublicInfoHintsSuccess({ hints })
            ),
            catchError(({ error }) =>
              of(SearchHintsActions.searchPublicInfoHintsFailure({ error }))
            )
          );
        })
      )
  );

  goToSearch$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchHintsActions.goToSearch),
        withLatestFrom(this.searchFacade.query$),
        tap(
          ([
             {
               query: { term },
             },
             query,
           ]) => {
            const route = <ActivatedRoute>(
              this.routerUtilsService.getRoute(this.router)
            );

            this.router.navigate(['search'], {
              relativeTo: route,
              queryParams: {
                term,
                size: this.defaultSize,
                ...(query.types?.length ? { types: query.types }:{}),
                ...(query.beneficiaryTypes?.length
                  ? { beneficiaryTypes: query.beneficiaryTypes }
                  :{}),
                ...(query.payment?.length ? { payment: query.payment }:{}),
                ...(query.serviceType
                  ? { serviceType: query.serviceType }
                  :{}),
              },
            });
          }
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private publicInfoService: PublicInfoSearchService,
    private router: Router,
    private routerUtilsService: RouterUtilsService,
    private searchFacade: SearchFacade,
    @Inject(SIZE) private defaultSize: number
  ) {
  }
}
