import { createSelector } from '@ngrx/store';
import { selectSearchState } from '../index';
import { State } from '../reducers/search-hints.reducer';

export const getSearchHintState = createSelector(
  selectSearchState,
  (state) => state.searchHints
);

export const getSearchHints = createSelector(
  getSearchHintState,
  (state: State) => state.hints
);

export const getSearchHintLoaded = createSelector(
  getSearchHintState,
  (state: State) => state.loaded
);

export const getSearchHintError = createSelector(
  getSearchHintState,
  (state: State) => state.error
);
