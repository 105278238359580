import { HttpClient } from '@angular/common/http';
import { Inject, NgModule } from '@angular/core';
import {
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { AvailableLangs, langList, langMap } from './constants/available-langs';
import { createTranslateLoader } from './loaders/translate-loader';
import { DEFAULT_LANGUAGE, LANG_LIST, LANG_MAP } from './tokens/lang'

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    })],
  providers: [
    {
      provide: DEFAULT_LANGUAGE,
      useValue: AvailableLangs.Ro
    },
    {
      provide: LANG_MAP,
      useValue: langMap
    },
    {
      provide: LANG_LIST,
      useValue: langList
    }, CookieService
  ]
})
export class PspTranslateUtilsModule {

  constructor(private translate: TranslateService,
              @Inject(DEFAULT_LANGUAGE) private defaultLanguage: string) {
    translate.setDefaultLang(defaultLanguage);
  }
}

