import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PublicInfoSearchService } from '../../infastructure/public-info-search.service';
import * as FilterConfigActions from '../actions/filter.actions';

@Injectable()
export class FiltersEffects {
  loadFilterConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterConfigActions.loadFilterConfig),
      switchMap(() =>
        this.publicInfoSearchService.getFilterConfiguration().pipe(
          map((filterConfig) =>
            FilterConfigActions.loadFilterConfigSuccess({
              filterConfig,
            })
          ),
          catchError(({ error }) =>
            of(FilterConfigActions.loadFilterConfigFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private publicInfoSearchService: PublicInfoSearchService
  ) {
  }

  ngrxOnInitEffects(): Action {
    return FilterConfigActions.loadFilterConfig();
  }
}
