import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthFacade } from '../application-services/facade/auth.facade';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authFacade: AuthFacade) {
  }

  mapRequest(request) {
    return request.clone({
      withCredentials: true,
      setHeaders: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = this.mapRequest(request);

    return next.handle(request).pipe(
      catchError(error => {
        if (error.status===401) {
          this.authFacade.logout();
        }

        return throwError(error);
      })
    );
  }
}
