import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeedbackDto } from '../domain/feedback';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(private http: HttpClient, private fb: FormBuilder) {}

  createFeedback(feedback) {
    return this.http.put<FeedbackDto>(`rsspa-api/feedback/create`, feedback);
  }

  updateFeedback(id, feedback) {
    return this.http.put<FeedbackDto>(
      `rsspa-api/feedback/${id}/update`,
      feedback
    );
  }

  getFeedbackForm() {
    return this.fb.group({
      id: [''],
      commentary: ['', [Validators.required, Validators.maxLength(1200)]],
      fullName: [''],
      email: [''],
    });
  }
}
