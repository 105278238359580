import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthFacade } from '../application-services/facade/auth.facade';
import { User } from '../domain/user';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private authFacade: AuthFacade,
    private permissionsService: NgxPermissionsService
  ) { }

  getUserDetails(): Observable<HttpResponse<User>> {
    return this.http.get<User>('rsspa-api/account/user-details', { observe: 'response' });
  }

  getConfig() {
    return this.http.get('assets/config.json');
  }

  checkAuth() {
    return this.getUserDetails().toPromise()
      .then(response => {
        if (response.status === 204) {
          this.getConfig()
            .toPromise()
            .then(config => {
              this.authFacade.getConfigSuccess(config);
              return response; // Pass the response forward
            });
          return null; // No content, return null
        }
        return this.getConfig()
          .toPromise()
          .then(config => {
            this.authFacade.getConfigSuccess(config);
            return response; // Pass the response forward
          });
      })
      .then(response => {
        if (response) {
          const user = response.body;
          this.permissionsService.loadPermissions(user.roles);
          this.authFacade.getUserDetailsSuccess(user);
        }
      })
      .catch(err => err);
  }
}
