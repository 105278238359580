import { Pipe, PipeTransform } from '@angular/core';
import { stripTags } from '@ps-frontend/psp/shared/util/helpers';
import { isString } from 'lodash';

@Pipe({
  name: 'psEllipsis',
})
export class EllipsisPipe implements PipeTransform {
  transform(str: string, strLength: number = 250) {
    if (!str || !isString(str)) {
      return str;
    }

    const withoutHtml = stripTags(str);

    if (str.length >= strLength) {
      return `${withoutHtml.slice(0, strLength)}...`;
    }

    return withoutHtml;
  }
}
