import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as FeedbackActions from '../actions/feedback.actions';
import * as fromFeedback from '../index';
import * as FeedbackSelectors from '../selectors/feedback.selectors';

@Injectable({
  providedIn: 'root',
})
export class FeedbackFacade {
  feedbackId$ = this.store.pipe(
    select(FeedbackSelectors.getFeedbackId)
  );
  showFeedbackDialog$ = this.store.pipe(
    select(FeedbackSelectors.showFeedbackDialog)
  );
  canSendFeedback$ = this.store.pipe(
    select(FeedbackSelectors.canSendFeedback)
  );
  isPositiveFeedback$ = this.store.pipe(
    select(FeedbackSelectors.isPositiveFeedback)
  );
  showComment$ = this.store.pipe(
    select(FeedbackSelectors.showComment)
  );

  constructor(private store: Store<fromFeedback.State>) {
  }

  rateService(feedback) {
    this.store.dispatch(FeedbackActions.rateService({ feedback }));
  }

  createFeedback(feedback) {
    this.store.dispatch(FeedbackActions.createFeedback(feedback));
  }

  updateFeedback(id, feedback) {
    this.store.dispatch(
      FeedbackActions.updateFeedback({
        id: id,
        feedback: feedback,
      })
    );
  }

  submitFeedback(id, feedback) {
    this.store.dispatch(FeedbackActions.submitFeedback({ id, feedback }));
  }

  submitNegativeFeedback(feedback) {
    this.store.dispatch(FeedbackActions.submitNegativeFeedback({ feedback }));
  }

  showFeedbackDialog(status) {
    this.store.dispatch(FeedbackActions.showFeedbackDialog({ status }));
  }

  toggleCanSendFeedback(canSend) {
    this.store.dispatch(FeedbackActions.toggleCanSendFeedback({ canSend }));
  }

  sendFeedback(feedback) {
    this.store.dispatch(FeedbackActions.sendFeedback({ feedback }));
  }

  clearState() {
    this.store.dispatch(FeedbackActions.clearState());
  }
}
