import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Actions, createEffect } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { AuthFacade } from '@ps-frontend/psp/auth/domain';
import { Resource } from '@ps-frontend/psp/shared/util/models';
import { RouterUtilsService } from '@ps-frontend/shared/util';
import { isObject } from 'lodash';
import { combineLatest, merge } from 'rxjs';
import { filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { SeoService } from '../../infrastructure/seo.service';

@Injectable()
export class SeoEffects {
  setMeta$ = createEffect(
    () =>
      combineLatest([
        this.authFacade.config$.pipe(filter((config) => !!config.host)),
        merge(
          this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
          ),
          this.translate.onLangChange
        ),
      ]).pipe(
        map(
          () => this.routerUtilsService.getRoute(this.router) as ActivatedRoute
        ),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
        withLatestFrom(this.authFacade.config$),
        tap(([data, config]) => {
          this.seoService.updateOgImage(
            `${config.host}assets/images/og-image.png`
          );

          data = data?.data || data;

          const translateTag = this.translateMetaTag(
            this.translate.currentLang,
            this.translate.defaultLang
          );

          this.seoService.updateTitle(
            `${translateTag('title')}-${translateTag(data['title'])}`
          );
          this.seoService.updateDescription(
            `${translateTag(data['description'])}`
          );

          this.seoService.updateOgTitle(
            `${translateTag('title')}-${translateTag(data['ogTitle'])}`
          );
          this.seoService.updateOgDescription(
            translateTag(data['ogDescription'])
          );

          const ogUrl =
            data['ogUrl'] || data['ogUrl'] === ''
              ? `${config.host}${this.translate.currentLang}${data['ogUrl']}/`
              : '';
          this.seoService.updateOgUrl(ogUrl);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private seoService: SeoService,
    private routerUtilsService: RouterUtilsService,
    private translate: TranslateService,
    private authFacade: AuthFacade
  ) {}

  translateMetaTag(lang, defaultLang): (value: Resource | string) => string {
    return (value) => {
      if (!value) {
        return '';
      }

      const isResource = isObject(value);
      const result = isResource ? value[lang] || value[defaultLang] : value;

      return result ? this.translate.instant(result) : '';
    };
  }
}
