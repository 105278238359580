import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '@ps-frontend/psp/shared/util/state';
import * as fromFeedbackEntity from './reducers/feedback.reducer';

export const feedbackFeatureKey = 'feedbackEntity';

export interface FeedbackState {
  [fromFeedbackEntity.feedbackEntityFeatureKey]: fromFeedbackEntity.State,
}

export interface State extends fromRoot.State {
  readonly [feedbackFeatureKey]: FeedbackState;
}

export function reducers(state: FeedbackState | undefined, action: Action) {
  return combineReducers({
    [fromFeedbackEntity.feedbackEntityFeatureKey]: fromFeedbackEntity.reducer,
  })(state, action);
}

export const selectFeedbackState = createFeatureSelector<State, FeedbackState>(
  feedbackFeatureKey
);


