import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import * as FilterConfigActions from '../actions/filter.actions';
import * as fromSearch from '../index';
import { SearchActions, SearchHintsActions } from '../index';
import * as SearchHintsSelectors from '../selectors/search-hints.selectors';
import * as SearchSelectors from '../selectors/search.selectors';

@Injectable({
  providedIn: 'root',
})
export class SearchFacade {
  loading$ = this.store.pipe(select(SearchSelectors.getSearchLoading));
  allSearch$ = this.store.pipe(select(SearchSelectors.getAllSearch));
  selectedSearch$ = this.store.pipe(select(SearchSelectors.getSelected));
  errorSearch$ = this.store.pipe(select(SearchSelectors.getSearchError));
  query$ = this.store.pipe(select(SearchSelectors.getQuery));
  term$ = this.store.pipe(select(SearchSelectors.getQueryTerm));
  total$ = this.store.pipe(select(SearchSelectors.totalCount));
  remaining$ = this.store.pipe(select(SearchSelectors.remaining));
  hints$ = this.store.pipe(select(SearchHintsSelectors.getSearchHints));
  hintLoaded$ = this.store.pipe(
    select(SearchHintsSelectors.getSearchHintLoaded)
  );
  hintError$ = this.store.pipe(select(SearchHintsSelectors.getSearchHintError));
  canShowMore$ = this.store.pipe(select(SearchSelectors.canShowMore));
  filterConfig$ = this.store.pipe(select(SearchSelectors.getFilterConfig));
  hasFilter$ = this.store.pipe(select(SearchSelectors.hasFilter));

  constructor(private store: Store<fromSearch.State>) {
  }

  goToSearch(query) {
    this.store.dispatch(SearchHintsActions.goToSearch(query));
  }

  search(query?) {
    this.store.dispatch(SearchActions.searchPublicInfo(query));
  }

  loadMore() {
    this.store.dispatch(SearchActions.loadMore());
  }

  getHints(query) {
    this.store.dispatch(SearchHintsActions.searchPublicInfoHints(query));
  }

  flushSearch() {
    this.store.dispatch(SearchActions.flushSearch());
  }

  flushSearchHints() {
    this.store.dispatch(SearchHintsActions.flushSearchHints());
  }

  applyFilters(filters) {
    this.store.dispatch(SearchActions.applyFilters({ filters }));
  }

  getFilterConfig() {
    this.store.dispatch(FilterConfigActions.loadFilterConfig());
  }
}
