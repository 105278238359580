export function getExtensionImg(extension) {
  extension = extension.toLocaleLowerCase();

  if (extension === 'pdf') {
    return `/assets/images/file-types/pdf.svg`;
  }

  if (extension === 'png') {
    return `/assets/images/file-types/png.svg`;
  }

  if (extension === 'csv') {
    return `/assets/images/file-types/csv.svg`;
  }

  if (['xls', 'xlsx'].includes(extension)) {
    return `/assets/images/file-types/excel.svg`;
  }

  if (['doc', 'docx'].includes(extension)) {
    return `/assets/images/file-types/word.svg`;
  }

  if (['jpg', 'jpeg'].includes(extension)) {
    return `/assets/images/file-types/jpg.svg`;
  }
}
