import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromFeedback from './application-services';
import { FeedbackEffects } from './application-services/effects/feedback.effects';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from '@ps-frontend/psp/shared/util/environments';

@NgModule({
  imports: [
    StoreModule.forFeature(fromFeedback.feedbackFeatureKey, fromFeedback.reducers),
    EffectsModule.forFeature([FeedbackEffects]),
    RecaptchaV3Module
  ],
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaSiteKey },
  ]
})
export class PspSharedFeedbackDomainModule {}
