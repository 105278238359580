import { InjectionToken } from '@angular/core';
import { Resource } from '@ps-frontend/psp/shared/util/models';

enum SearchInfoTypes {
  PublicService = 'PublicService',
  Event = 'PublicEvent',
}

export interface SearchInfoItem {
  id: string;
  code: string;
  title: Resource;
  description: Resource;
  type: SearchInfoTypes;
  iconClass: string;
  eService: boolean;
  ownerOrganization: Resource;
}

export interface SearchResult {
  items: SearchInfoItem[];
  totalCount: number;
}

export interface SearchQuery {
  term: string;
  from: number;
  size: number;
  filters?: any;
  types?: string[];
  payment?: string[];
  beneficiaryTypes?: string[];
  serviceType?: string;
}

export const defaultSize = 50;
export const defaultFrom = 0;

export const SIZE: InjectionToken<string> = new InjectionToken('SIZE');
export const FROM: InjectionToken<string> = new InjectionToken('FROM');

export const calculateSize = (size, from) =>
  Math.round(Number(size) + Number(from));
