import { createSelector } from '@ngrx/store';
import { isObject, some } from 'lodash';
import { selectSearchState } from '../index';
import { searchAdapter, searchResultFeatureKey, State, } from '../reducers/search.reducer';

export const getSearchResultsState = createSelector(
  selectSearchState,
  (state): State => state[searchResultFeatureKey]
);

export const {
  selectAll: getAllSearch,
  selectEntities: getSearchEntities,
  selectTotal,
} = searchAdapter.getSelectors(getSearchResultsState);

export const getSearchLoading = createSelector(
  getSearchResultsState,
  (state: State) => state.loading
);

export const getSearchError = createSelector(
  getSearchResultsState,
  (state: State) => state.error
);

export const getSelectedId = createSelector(
  getSearchResultsState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getSearchEntities,
  getSelectedId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

export const getQuery = createSelector(
  getSearchResultsState,
  (state: State) => state.query
);

export const getQueryTerm = createSelector(getQuery, (query) => query.term);

export const totalCount = createSelector(
  getSearchResultsState,
  (state: State) => state.totalCount
);

export const canShowMore = createSelector(
  selectTotal,
  totalCount,
  getSearchLoading,
  (totalInStore, total, loading) => total > totalInStore && !loading
);

export const remaining = createSelector(
  selectTotal,
  totalCount,
  (totalInStore, total) => total - totalInStore
);

export const getFilterConfig = createSelector(
  getSearchResultsState,
  (state) => state.filterConfig
);

export const hasFilter = createSelector(getFilterConfig, (filterConfig) =>
  some(filterConfig, (configProp) => {
    if (isObject(configProp)) {
      return some(configProp, (c) => c);
    }

    return configProp;
  })
);
