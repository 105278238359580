import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RouterUtilsService } from '@ps-frontend/shared/util';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom, } from 'rxjs/operators';
import { calculateSize, SearchResult } from '../../domain/search-result';
import { PublicInfoSearchService } from '../../infastructure/public-info-search.service';
import * as SearchActions from '../actions/search.actions';
import { SearchFacade } from '../facades/search.facade';

@Injectable()
export class SearchEffects {
  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.searchPublicInfo),
      withLatestFrom(this.searchFacade.query$),
      switchMap(([{ query = {} }, stateQuery]) => {
        const term = query.term ?? stateQuery.term;

        if (!term || term === '' || term.length < 2) {
          return of(SearchActions.noSearch());
        }

        query = {
          ...stateQuery,
          ...query,
        };

        return this.publicInfoService.search(query).pipe(
          tap(() => {
            const route = <ActivatedRoute>(
              this.routerUtilsService.getRoute(this.router)
            );

            this.router.navigate([], {
              relativeTo: route,
              queryParams: {
                term: query.term,
                size: query.size ?? stateQuery.size,
                ...(query.types.length ? { types: query.types }:{}),
                ...(query.beneficiaryTypes.length
                  ? { beneficiaryTypes: query.beneficiaryTypes }
                  :{}),
                ...(query.payment.length ? { payment: query.payment }:{}),
                ...(query.serviceType
                  ? { serviceType: query.serviceType }
                  :{}),
              },
            });
          }),
          map((searchResult: SearchResult) =>
            SearchActions.searchSuccess({ searchResult })
          ),
          catchError(({ error }) => of(SearchActions.searchFailure({ error })))
        );
      })
    )
  );

  loadMore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.loadMore),
      withLatestFrom(this.searchFacade.query$),
      switchMap(([action, query]) =>
        this.publicInfoService.search(query).pipe(
          tap(() => {
            const route = <ActivatedRoute>(
              this.routerUtilsService.getRoute(this.router)
            );
            this.router
              .navigate([], {
                relativeTo: route,
                queryParams: {
                  size: calculateSize(query.size, query.from),
                },
                queryParamsHandling: 'merge',
              })
              .then(() => {
                this.viewportScroller.scrollToPosition([
                  0,
                  this.document?.body.scrollHeight,
                ]);
              });
          }),
          map((searchResult: SearchResult) =>
            SearchActions.loadMoreSuccess({ searchResult })
          ),
          catchError(({ error }) =>
            of(SearchActions.loadMoreFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private publicInfoService: PublicInfoSearchService,
    private searchFacade: SearchFacade,
    private router: Router,
    private viewportScroller: ViewportScroller,
    @Inject(DOCUMENT) private document: Document,
    private routerUtilsService: RouterUtilsService
  ) {
  }
}
