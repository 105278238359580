import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '@ps-frontend/psp/shared/util/state';
import * as fromAuth from './reducers/auth.reducer';

export const authFeatureKey = 'auth';

export interface AuthState {
  [fromAuth.authKey]: fromAuth.State
}

export interface State extends fromRoot.State {
  readonly [authFeatureKey]: AuthState;
}

export function reducers(state: AuthState, action: Action) {
  return combineReducers({
    [fromAuth.authKey]: fromAuth.reducer
  })(state, action);
}

export const selectAuthFeatureState = createFeatureSelector<State, AuthState>(authFeatureKey);
