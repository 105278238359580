import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'psStripUrlPrefix'
})
export class StripUrlPrefixPipe implements PipeTransform {

  transform(url: string): string {
    return url?.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
  }

}
